import React from 'react'
import { Box, Grid, Typography } from '@mui/material';
import PropertyImage from '../../../../property/PropertyCard/Image';
import { height, margin, styled } from '@mui/system';
import { LinkItem } from '../../../../helper/myaccountLink';
import { handleTabNavigation } from '../../../../utils';

const WrapBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: 0,
  // [theme.mixins.shadow],
  borderRadius: "8px",
  boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 2px 4px 0px rgba(0, 0, 0, 0.06)",
  [theme.breakpoints.down('md')]: {
    borderRadius: "0",
    boxShadow: "none",
  },
  "& a":{
    color: theme.palette.secondary.main,
    textDecoration: "underline"
  },
  "& .property-image": {
    height: "100%",
    "& img": {
      height: "100%",
      width: "100%",
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: "8px",
      boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 2px 4px 0px rgba(0, 0, 0, 0.06)",
      // borderRadius: "0",
      // boxShadow: "none",
      width: "calc(100% + 5rem)",
      // marginLeft: 'calc(-50vw + 50%)', // Center the image
      // marginRight: 'calc(-50vw + 50%)', // Center the image
      marginLeft: "-2.5rem",
      marginTop: "-0.5rem",
    },
    [theme.breakpoints.down('sm')]: {
      width: "calc(100% + 2rem)",
      // marginLeft: 'calc(-50vw + 50%)', // Center the image
      // marginRight: 'calc(-50vw + 50%)', // Center the image
      marginLeft: "-1rem",
      marginTop: "-1rem",
    }
  },
  "& .property-info": {
    padding: theme.spacing(2),
    background: theme.palette.background.white,
    borderRadius: 1,
    height: '100%',
    "& .inner": {
      borderBottom: '1px solid', borderColor: theme.palette.background.border, marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.down('md')]: {
      position: "relative",
      zIndex: 2,
      marginTop: "-70px",
      height: "auto",
      borderRadius: "8px",
      boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 2px 4px 0px rgba(0, 0, 0, 0.06)",
    },
    
  },
  "& .key-value-pair": {
      display: 'flex',
      gridTemplateColumns: '150px 1fr',
      gap: theme.spacing(1),
      marginBottom: theme.spacing(1),
      justifyContent: "space-between",
      [theme.breakpoints.up('sm')]: {
        display: 'grid',
      }
  },
  "& .tenancy-info": {
    padding: theme.spacing(2),
    // bgcolor: 'grey.200',
    borderRadius: 1,
    height: '100%',
    "& .inner": {
      borderBottom: '1px solid', borderColor: theme.palette.background.border, marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.down('md')]: {
      background: theme.palette.background.white,
      marginTop: theme.spacing(2),
      borderRadius: "8px",
      boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 2px 4px 0px rgba(0, 0, 0, 0.06)",
    }
  }

  // Mobile 
}));

const KeyValuePair = ({ keyName, value, link = false, urlParams={}}) => {
  let linkUrl = link
  let linkParams = {}
  if (linkUrl) {
    const externalLink = (link.match(/https|http/))
    let linkParams = {}
    if (!externalLink) {
      linkUrl = handleTabNavigation( link, urlParams, true);
    } else {
      linkParams = {
        target: "_blank",
      }
    }
  }
  return (
    <Box
      className='key-value-pair'
    >
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        {keyName}:
      </Typography>
      <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
      {link && (
          <LinkItem to={`${linkUrl}`}>{value}</LinkItem>
        )}
        {!link && (
          <>{value}</>
        )}
      </Typography>
  </Box>
  )};

  const ThreeColumnGridWithImage = (props) => {
    const { propertyInfo, tenancyInfo, urlParams } = props
    return (
      <WrapBox>
        <Grid container spacing={0}>
          {/* First Column */}
          <Grid item xs={12} md={4}>
          <PropertyImage
            property={props?.property}
            className='property-image'
          />
          </Grid>
  
          {/* Second Column */}
          <Grid item xs={12} md={4}>
            <Box
              className='property-info'
            >
                <Typography variant="h2" gutterBottom>
                {propertyInfo.title}
                </Typography>
                <Box className="inner" sx={{  }} />
                  {propertyInfo.values.map((item) => {
                    if (item?.link) {
                      return(
                        <KeyValuePair keyName={item.name} value={item.value} link={item.link} urlParams={urlParams}/>
                      )
                    }
                    return(
                      <KeyValuePair keyName={item.name} value={item.value} />
                    )
                  })}
                </Box>
          </Grid>
  
          {/* Third Column */}
          <Grid item xs={12} md={4}>
            {tenancyInfo && (
            <Box
            className='tenancy-info'
          >
              <Typography variant="h2" gutterBottom>
                {tenancyInfo.title}
                </Typography>
                <Box className="inner" sx={{  }} />
                  {tenancyInfo.values.map((item) => {
                    return(
                      <KeyValuePair keyName={item.name} value={item.value} />
                    )
                  })}
                </Box>
            )}
          </Grid>
        </Grid>
      </WrapBox>
    );
  };
  

export default ThreeColumnGridWithImage