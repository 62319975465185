import React from "react"
import { get } from "lodash"
import { useParams } from '@reach/router';
import Grid from "@mui/material/Grid"
import FeedbackProgress from "../../../../feedbackProgress"
import Tabs from "../../../../tabs"
import ActionsSkeleton from "../../../../actions/actionsSkeleton"
import range from "lodash/range"
import Stack from "@mui/material/Stack"
import Skeleton from "@mui/material/Skeleton"
import FeedbackListItem from "./list"
import { useGetPropertyFeedbackQuery } from "./../../../../../../redux/services/property"
import { isAuthenticated } from "../../../../../../services/store/utils"
import IntroText from "../tabIntro";
import SectionTitle from "../sectionTitle";
import { DocumentBox, FilterBox } from "../../../../StyleComp";
import { Box } from "@mui/material";
import myaccountData from '../../../../../../../../static/data/myaccount.json';
import CircularProgressComponent from "../../../../progress/CircularProgress";

const Content = props => {
    const authUser = isAuthenticated()
    const property = props.property.property_detail
    const urlParams = useParams()
    const tabName = urlParams?.tab;
    const {
        data: propertyFeedbacks,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetPropertyFeedbackQuery(
        { property_id: property.crm_id },
        { skip: !authUser }
    )
    const isMobile = props.mobile
    const actionTitle = props?.offer?.actionTitle || "Feedback"
    const feedbackData = propertyFeedbacks?.feedbacks || []
    const feedbackStats = propertyFeedbacks?.stats || []
    const feedbackCount = propertyFeedbacks?.total || 0
    const tabsDescription = get(props, `${props?.persona}.tabsDescription.feedback`, get(props, 'tabsDescription.feedback', false))

    const noRecord = !isLoading && !feedbackData.length

    if (isLoading) {
        return <CircularProgressComponent />
        return (
            <Grid container>
                <Grid item md={8}>
                    <ActionsSkeleton title />
                </Grid>
                <Grid item md={4}>
                    <Stack direction="column" spacing={2}>
                        <Skeleton variant="text" width="60%" height={40} />
                        {range(0, 8).map(index => (
                            <Stack key={index} direction="column" spacing={1}>
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={7}
                                />
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                >
                                    <Skeleton
                                        variant="text"
                                        width={100}
                                        height={15}
                                    />
                                    <Skeleton
                                        variant="text"
                                        width={50}
                                        height={15}
                                    />
                                </Stack>
                            </Stack>
                        ))}
                    </Stack>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
            <SectionTitle
                {...props}
                title={actionTitle}
                tabsDescription={'feedback'}
                tabsTitle={property?.display_address}
                actions={['contactus']}
            />

            <DocumentBox>
                <Box className="content">
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={8} order={{ xs: 2, sm: 2, md:1}}>
                                    <FeedbackListItem
                                        noRecord={noRecord}
                                        data={feedbackData}
                                        // image={propertyImage}
                                        title={actionTitle}
                                        // title="Tenancies"
                                        // reloadDataFun={reloadDataFun}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} order={{ xs: 1, sm: 1, md: 2}}>
                                    <FeedbackProgress
                                        theme={props.theme}
                                        title="Feedback Summary"
                                        data={feedbackStats}
                                        count={feedbackCount}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </DocumentBox>
        </Grid>
        </Grid>
    )
}

export default Content
