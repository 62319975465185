import React from "react"
import { useParams } from '@reach/router';
import { get } from "lodash"
import NoActions from "../../../../progress/NoActions"
import ActionsSkeletonWithMore from "../../../../actions/actionsSkeletonWithMore"
import MakeOfferBtn from "../../../../property/components/makeOffer"
import ListItem from "./list"
import {
    useGetPropertyOffersQuery,
    propertyApi,
} from "./../../../../../../redux/services/property"
import store from "./../../../../../../redux/store"
import { isAuthenticated } from "../../../../../../services/store/utils"
import IntroText from "../tabIntro";
import SectionTitle from "../sectionTitle";
import { DocumentBox, FilterBox } from "../../../../StyleComp";
import { Box, Grid, Typography } from "@mui/material";
import myaccountData from '../../../../../../../../static/data/myaccount.json';

const Content = props => {
    const authUser = isAuthenticated()
    const property = props.property.property_detail
    const urlParams = useParams()
    const tabName = urlParams?.tab;
    const {
        data: propertyOffers,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetPropertyOffersQuery(
        { property_id: property.crm_id },
        { skip: !authUser }
    )
    const offerData = propertyOffers || []
    const property_detail = props?.property?.property_detail || {}
    const noRecord = !isLoading && !offerData.length
    const propertyImage = props.property.property_detail?.thumbnail
    const actionTitle = props?.offer?.actionTitle || "Offers"
    const listDisclaimer = props?.offer?.listDisclaimer || ""
    // const showOfferBtn = (get(
    //     props,
    //     `${props.role || ""}.tabOfferOfferBtn`,
    //     false
    // ))
    const showAddIcon = false // get(props, `${props.role || ""}.showAddIcon`, false)

    const reloadDataFun = React.useCallback(() => {
        if (props?.role && !["tenant", "buyer"].includes(props?.role))
            store.dispatch(
                propertyApi.util.invalidateTags(["PendingActionCount"])
            )
        store.dispatch(propertyApi.util.invalidateTags(["PropertyOffers"]))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading && !offerData.length) {
        return <ActionsSkeletonWithMore title />
    }

    let  showOfferBtn = false
    if (get(props, `${props.role || ""}.showMakeAnOfferBtn`, false))
        showOfferBtn = true

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
            <SectionTitle
                {...props}
                title={`Offers`}
                tabsDescription={'offers'}
                tabsTitle={property_detail?.display_address}

                // for offer Btn
                showAddIcon={false}
                property={props.property.property_detail}
                reloadDataFun={reloadDataFun}
                actions={[(showOfferBtn ? 'makeanoffer' : ''), 'contactus']}
            />

            <DocumentBox>
                <Box className="content">
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box className="filter-box-wrap">
                                {!!offerData.length && <Typography>Showing {offerData.length} offers</Typography>}
                                <FilterBox>
                                {/* <p>asas</p> */}
                                </FilterBox>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <ListItem
                                noRecord={noRecord}
                                data={offerData}
                                image={propertyImage}
                                title={actionTitle}
                                // title="Tenancies"
                                reloadDataFun={reloadDataFun}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DocumentBox>
        </Grid>
        </Grid>
    )
}

export default Content
