import React, { memo } from 'react';
import NoActions from "../../../../progress/NoActions";
// import Action from '../../../../actions/action/tenancy';
import Action from '../../../../actions/action/utilities/action';
import { FormStoreProvider } from "../../../../../../services";
import ComponentLayout from '../../../../ComponentLayout';
import { ComponentWithPagination } from "../../../../pagination/"
import { Divider, useMediaQuery, useTheme } from '@mui/material';
import { ListActionsBoxWrap } from '../../../../StyleComp';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Grid, Card, CardContent, Typography, Link, Icon, IconButton, Box } from '@mui/material';
import { displayData } from '../../../../helper/dateformat';
import StarberryIcons from '../../../../icons';
import DownloadBtn from '../document/downloadBtn';
import { formatPrice } from '../../../../helper/action';
import { getRentFrequencyCode } from '../../../../helper';
import { getTitleTenancy, getStatusTenancy, getDateTenancy } from '../utils';

const getIconName = (key) => {
  console.log('keyyyyy', key)
  switch (key) {
    case 'Gas':
      return 'customGasIcon'

    case 'Power':
      return 'customElectricityIcon'

    case 'Water':
      return 'customWaterIcon'

    default:
      return 'waterIcon';
  }
}

const ListItem = ({data, image, ...rest}) => {
    return(
        <ListActionsBoxWrap>
        <Grid container spacing={2}>
          {data.map((row, index) => (
            <Grid item xs={12} md={4} lg={4}>
            <Card className="action-card-item d-block grid-card" key={index}>
              <Grid container spacing={2} alignItems="baseline">
                <Grid item className="action-icon"><Typography>.</Typography>
                  <StarberryIcons iconName={getIconName(row.type)} />
                </Grid>
                <Grid item xs>
                  <CardContent>
                    <Typography variant="h2">{row.type}</Typography>
                    <Typography className="info-text" variant="body2" color="textSecondary">
                      Reading: {row.reading}
                    </Typography>
                    <Typography className="info-text grey" variant="body2" color="textSecondary">
                      Date: {displayData(row.date, 'date1')}
                    </Typography>
                    {/* {row?.showActionButton && ( */}
                        {/* <Action data={{...row, image:image}} {...rest} roleType="owner" step={'default'} cardWithAction={true} /> */}
                    {/* )} */}
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
            </Grid>
          ))}
        </Grid>

        </ListActionsBoxWrap>
    )
}

const List = ({noRecord, data, title, ...rest}) => {
    const noResult = noRecord || !data.length;
    
    return(
        <>
            {(noResult) && (
                    <NoActions
                        title={`There are no ${title}`}
                        icon="tenancyIcon"
                    />
                )}
                {!noResult && (
                <>
                    {/* <ComponentLayout
                        // title={`${title} (${data.length})`}
                    > */}
                        <ComponentWithPagination
                            {...rest}
                            component={ListItem}
                            data={data}
                            marginTop={25}
                            useLoadMore={false}
                            // pageSize={1}
                        />
                    {/* </ComponentLayout> */}
                </>
            )}
        </>
    )
}

export default memo(List);