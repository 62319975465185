import React from "react"
import { get } from "lodash"
import { useParams } from '@reach/router';
import ActionsSkeleton from "../../../../actions/actionsSkeleton"
import ListItem from "./list"
import {
    useGetPropertyAppointmentsQuery,
    propertyApi,
} from "./../../../../../../redux/services/property"
import store from "./../../../../../../redux/store"
import { isAuthenticated } from "../../../../../../services/store/utils"
import { DocumentBox, FilterBox } from "../../../../StyleComp";
import { Box, Grid, Typography } from "@mui/material";
import SectionTitle from "../sectionTitle";
import myaccountData from '../../../../../../../../static/data/myaccount.json';

// Calendar Action Container wrap for enable loading
const AppointmentsContent = props => {
    console.log('props ssss', props)
    const authUser = isAuthenticated()
    const property = props.property.property_detail
    const urlParams = useParams()
    const tabName = urlParams?.tab;

    const {
        data: propertyAppointments,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetPropertyAppointmentsQuery(
        { property_id:property.crm_id },
        { skip: !authUser }
    )
    const data = propertyAppointments || []
    const noRecord = !isLoading && !data.length
    const propertyImage = property?.thumbnail

    const actionTitle = props?.offer?.actionTitle || "Appointments"
    const property_detail = props?.property?.property_detail || {}
    const tabsDescription = get(props, `${props?.persona}.tabsDescription.appointments`, get(props, 'tabsDescription.appointments', false))

    const reloadDataFun = React.useCallback(() => {
        store.dispatch(
            propertyApi.util.invalidateTags([
                "PendingActionCount",
                "PropertyAppointment",
            ])
        )
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading && !data.length) {
        return <ActionsSkeleton title />
    }

    let  showBookaViewingBtn = false
    if (get(props, `${props.role || ""}.showBookaViewingBtn`, false))
        showBookaViewingBtn = true

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
            <SectionTitle
                {...props}
                title={`Appointments`}
                tabsDescription={'appointments'}
                tabsTitle={property_detail?.display_address}

                property={props.property.property_detail}
                actions={[showBookaViewingBtn ? 'book-a-viewing' : '', 'contactus']}
            />

            <DocumentBox>
                <Box className="content">
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box className="filter-box-wrap">
                                <Typography>Showing {data.length} appointments</Typography>
                                <FilterBox>
                                {/* <p>asas</p> */}
                                </FilterBox>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <ListItem
                                noRecord={noRecord}
                                data={data}
                                image={propertyImage}
                                title={actionTitle}
                                // title="Tenancies"
                                reloadDataFun={reloadDataFun}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DocumentBox>
        </Grid>
        </Grid>
    )
}

export default React.memo(AppointmentsContent)
