import React, { useState } from "react"
import { get } from "lodash"
import { useParams } from '@reach/router';
import ComponentLayout from "../../../../ComponentLayout"
import NewDocumentBtn from "../../../../property/components/newDocument"
import ActionSkeletonWithMore from "../../../../actions/actionSkeletonWithMore"
import { canShowNewDocumentBtn } from "../../../../property/components/newDocument/utils"
import TextField from "@mui/material/TextField"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputAdornment from "@mui/material/InputAdornment"
import AccountCircle from "@mui/icons-material/Search"
import ListItem from "./list"
import { display, styled } from '@mui/system';
import { useGetPropertyDocumentsQuery } from "./../../../../../../redux/services/property"
import { isAuthenticated } from "../../../../../../services/store/utils"
import IntroText from "../tabIntro";
import DocumentContent from "./content"
import { Box, Button, Grid } from "@mui/material";
import PageTitle from "../../../../pageTitle";
import ContactBtn from '../../../../property/components/contactBtn';
import NewDocumentBtnWrap from "./newDocumentBtn";
import { PageTitleBox, DocumentBox } from "../../../../StyleComp";
import SectionTitle from "../sectionTitle";
import myaccountData from '../../../../../../../../static/data/myaccount.json';

// const HeaderBox = styled(Box)(({ theme }) => ({
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     marginBottom: theme.spacing(4),
//     "& .contact-btn": {
//         marginLeft: theme.spacing(2)
//     },
//     "& .action-btn": {
//         minWidth: "360px",
//         textAlign: "right",

//     },
//     [theme.breakpoints.down('md')]: {
//         display: 'block',
//         "& .action-btn": {
//             display: "none"
//         },
//     }
// }));

const Content = props => {
    const authUser = isAuthenticated()
    const property = props.property
    const urlParams = useParams()
    const tabName = urlParams?.tab;
    const {
        data: documents,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetPropertyDocumentsQuery(
        {
            property_id: property.property_detail.crm_id,
            document_section:
                props.type === "lettings" || props.type === "renting"
                    ? "tenancydocuments"
                    : "propertydocuments",
        },
        { skip: !authUser }
    )

    // const [filter, setFilter] = useState({
    //     query: "",
    //     type: false,
    // })

    const data = documents || []
    // const tabsDescription = get(props, `${props?.persona}.tabsDescription.documents`, get(props, 'tabsDescription.documents', false))

    // const noRecord = !isLoading && !data.length
    const propertyImage = property.property_detail?.thumbnail
    // const handleChange = (type, e) => {
    //     setFilter({
    //         ...filter,
    //         [type]: e.target.value,
    //     })
    // }

    const collectDocTypes = documents => {
        if (!documents)
            return []
        return [...new Set(documents.map(item => item.type))]
    }

    return (
        <DocumentContent
            {...props}
            collectDocTypes={collectDocTypes(documents)}
            documents={documents}
            property={property}
            propertyImage={propertyImage}
            error={error}
            isError={isError}
            isLoading={isLoading}
            isFetching={isFetching}
        />
    )
}

const ContentWrap = props => {
    const property_detail = props?.property?.property_detail || {}
    return(
        <DocumentBox>
            <Grid container spacing={1}>
                <Grid item xs={12} className="Sanmugam">
                    <SectionTitle
                        {...props}
                        title={`Documents`}
                        tabsDescription={'documents'}
                        tabsTitle={property_detail?.display_address}
                        actions={['newdocuemnt', 'contactus']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <div className="content">
                        <Content {...props} />
                    </div>
                </Grid>
            </Grid>
        </DocumentBox>
    )
    // return (
    //     <ComponentLayout>
    //         <Content {...props} />
    //     </ComponentLayout>
    // )
}

export default ContentWrap
