import React from "react"
import { useParams } from '@reach/router';
import _, { isEmpty } from "lodash"
import { getCurrentTab, getTabLists, collectMenuTabs } from "../../helper"
import { withSubtheme } from "../../../../StarberryComponentsMui"
import Grid from "@mui/material/Grid"
import useMediaQuery from "@mui/material/useMediaQuery"
import GlobalLayout from "../../Layout"
import Tabs from "../../tabs"
import MenuListTabs from "../../iconMenuList/menuListIconsTabs"
import SingleProperty from "../../property/singleProperty"
// import ContactModule from '../../contactModule';
// import SimpleContactModule from '../../contactModule/simpleContact';
import PendingItems from "../../pendingItems"
import PageTitle from "../../pageTitle"
// import Activities from '../../activities';
// import FeedbackProgress from '../../feedbackProgress';
import Container from "@mui/material/Container"
import FloatingContainer from "../../floatingContainer"
import ContactBtn from "../../property/components/contactBtn"
import _properties from "../../../../sample/properties.yaml"
import {
    MyaccountStoreProvider,
    PropertyActionStoreProvider,
    useAuthState,
} from "../../../../services"
// import Actions from '../../actions';
import PendingActions from "../../pendingActions/propertyDetails"
import RecentActivites from "../../recentActivities/propertyDetails"
// import OnlyActions from '../../pendingActions/propertyDetails';
// import ActionsWithLayout from '../../pendingActions/withGlobalLayout';
import NoActions from "../../progress/NoActions"
// import Calendar from '../../calendar';
// import { /* calendarDate, */getMonth } from '../../helper/dateformat';
// import { MYACCOUNT_INBOX } from "../../../../constants/urls";
import DocumentContent from "../../pages/my-property/common/document"
import ContactModuleComp from "../../pages/my-property/common/contactModule"
import AppointmentsContent from "../../pages/my-property/common/appointment"
import FeedbackContent from "../../pages/my-property/common/feedback"
import PaymentsContent from "../../pages/my-property/common/transaction"
import OfferContent from "../../pages/my-property/common/offer"
import StatsContent from "../../pages/my-property/common/stats"
import PerformanceContent from "../../pages/my-property/common/performance"
import MyPropertySkeleton from "./myPropertySkeleton"
import defaults from "./defaults"
import { useGetMyPropertyQuery } from "./../../../../redux/services/property"
import { isAuthenticated } from "../../../../services/store/utils"
import { TabMenuList } from "./common/tabMenuList";
import { getTabName } from "./common/utils";
import ThreeColumnGridWithImage from "./common/ThreeColumnGridWithImage";
import ThreeColumnGridWithMenu from "./common/ThreeColumnGridWithMenu";
import CustomCard from "../../cta";
import Price from "../../property/PropertyCard/Price";
import IntroText from "./common/tabIntro";
import SectionTitle from "./common/sectionTitle";
import UtilityContent from "./common/utilityreading";
import { camelCaseToNormalText } from "../../property/utils";

const OverviewContent = props => {
    const isMobile = props.mobile
    if (isMobile) {
        const tabLists = mainTabData();

        return (
            <div>
                <TabMenuList
                    tabLists={tabLists}
                    tabItems={props?.tabItems}
                />
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <TabsMobileComp {...props} />
                    </Grid>
                </Grid>
            </div>
        )
    } else {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} lg={8}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <ActivityTabsComp
                                theme={props.theme}
                                property={props.property}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PendingItemsComp property={props.property} />
                        </Grid>
                        <Grid item xs={12}>
                            <StatsContent
                                title="test"
                                property={props.property}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <SinglePropertyComp
                        theme={props.theme}
                        properties={props.properties}
                        property={props.property?.property_detail}
                    />
                    <FloatingContainerComp
                        property={props.property?.property_detail}
                        is_owner={props.property?.is_owner}
                        negInfo={props.property?.negotiator_info}
                    />
                    <ContactModuleComp
                        theme={props.theme}
                        property={props.property?.property_detail}
                        data={props.property?.negotiator_info}
                        moduleTitle="Contact your agent"
                    />
                    <ContactModuleComp
                        theme={props.theme}
                        property={props.property?.property_detail}
                        data={props.property?.manager_negotiator_info}
                        moduleTitle="Property manager"
                    />
                </Grid>
            </Grid>
        )
    }
}

// Calendar Action Container wrap for enable loading
// const ActionDataContainer = ({props, state, date}) => {
//
//     const data = state?.propertyAppointments || []
//     const monthName = getMonth(date);
//
//     if (state.loading_actions) {
//         return <CircularProgress />
//     }
//
//     if (!state.loading_actions && !data.length) {
//         return (
//             <NoActions
//                 title="There are no pending actions"
//                 message="At the moment all the actions are closed..."
//                 icon={PendingActionsIcon}
//             />
//         )
//     }
//
//     return (
//         <Actions
//             title={monthName}
//             {...props}
//             data={props?.data ? props.data : data}
//         />
//     )
// }

const PendingItemsComp = props => {
    const progressionData = props.property?.sales_progression || []
    return <PendingItems data={progressionData} title="Sales Progress" />
}

const FloatingContainerComp = props => {
    let showContactBtn = false
    if (isEmpty(props.negInfo)) {
        showContactBtn = true
    }

    if (!showContactBtn) return <></>

    return (
        <FloatingContainer>
            <ContactBtn btnLabel="Contact Us" {...props} />
        </FloatingContainer>
    )
}
const SinglePropertyComp = props => {
    let offerText = "" //'No offers received';
    if (props.property?.offers && props.property.offers.length) {
        offerText = `${props.property.offers.length} offers received`
    }

    let property = _properties[0] // Use default data if the property data is not there

    if (props.property) {
        property = {
            ...props.property,
            offerText,
        }
    }

    return (
        <>
            <SingleProperty
                theme={props.theme}
                properties={[property]}
                altClassName={`persona-details`}
            />
        </>
    )
}

const mainTabData = props => {
    return {
        overview: {
            iconName: "overviewTabIcon",
            id: "overview",
            name: "Overview",
            subtitle: "Lorem ipsum dolor sit amet",
            content: props ? <OverviewContent {...props} /> : '',
        },
        appointments: {
            id: "appointments",
            iconName: "appointmentsIcon",
            name: "Appointments",
            subtitle: "Lorem ipsum dolor sit amet",
            content: props ? <AppointmentsContent {...props} /> : '',
        },
        feedback: {
            id: "feedback",
            iconName: "feedbackIcon",
            name: "Feedback",
            subtitle: "Lorem ipsum dolor sit amet",
            content: props ? <FeedbackContent {...props} /> : '',
        },
        offers: {
            id: "offers",
            iconName: "offerIcon",
            name: "Offers",
            content: props ? <OfferContent {...props} /> : '',
        },
        documents: {
            id: "documents",
            iconName: "documentsIcon",
            name: "Documents",
            content: props ? <DocumentContent {...props} /> : '',
        },
        transactions: {
            id: "transactions",
            iconName: "transactionsIcon",
            name: "Transaction",
            content: props ? <PaymentsContent {...props} /> : '',
        },
        performance: {
            id: "performance",
            iconName: "marketingPerformance",
            name: "Performance",
            content: props ? <PerformanceContent {...props} /> : '',
        },
    }
}

const MainTabsComp = props => {
    const { services: authServices } = useAuthState()
    const urlParams = useParams()
    const property = props?.property
    let pageTitle = property?.property_detail?.display_address
    if (urlParams?.tab && urlParams?.tab != 'overview') {
        pageTitle = getTabName(urlParams?.tab)
    }

    // tabItems Can be Array or Objects
    const _tabItems = [
        "overview",
        "appointments",
        "feedback",
        "offers",
        "documents",
    ]
    const _defaultItem = "overview"
    const tabItems = props?.tab?.items || _tabItems
    const defaultItem = props?.tab?.default || _defaultItem
    const currentTab = getCurrentTab(defaultItem, tabItems)
    const availableTabs = mainTabData({...props, tabItems:tabItems}) || {}
    const data = getTabLists(availableTabs, tabItems)

    const [value, setValue] = React.useState(currentTab)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    React.useEffect(() => {
        // const pageRightDrawer = (
        //     <RightDrawerContent
        //         props={{
        //             property: property,
        //             manageValue: value,
        //             manageHandleChange: handleChange,
        //             tabItems,
        //             addUrl: true,
        //             data: data,
        //         }}
        //     />
        // )
        authServices.updateState({
            pageTitle: pageTitle,
            // pageRightDrawer: pageRightDrawer,
            pageLeftIcon: true,
            moreIcon: false,
            pageRightDrawer:false
        })
    }, [pageTitle, value]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Tabs
            data={data}
            manageValue={value}
            addUrl={true}
            tabItems={tabItems}
            altTabClassName={`pd-main`}
        />
    )
}

const ActivityTabsComp = props => {
    const dataActivity = [
        {
            id: "tab1",
            name: "Pending Actions",
            content: <PendingActions property={props?.property} />,
        },
        {
            id: "tab2",
            name: "Recent Activity",
            content: <RecentActivites property={props?.property} />,
        },
    ]
    return <Tabs data={dataActivity} altTabClassName={`pd-second`} />
}

const mobileTabData = props => {
    return [
        {
            id: "tab1",
            name: "Overview",
            content: (
                <>
                    {/* <PropertyActionStoreProvider> */}
                    <PendingActions
                        property={props?.property}
                        title="Pending Actions"
                        componentLayout={true}
                        // moreLabel="View all"
                        // moreLinkUrl={MYACCOUNT_INBOX}
                        // noActionTitle="There are no pending actions"
                        // noActionMessage="At the moment all the actions are closed..."
                    />
                    {/* </PropertyActionStoreProvider> */}
                    <PendingItemsComp property={props.property} />
                    {/*
                    <MenuListTabsComp
                        title="Property Overview"
                        manageValue={props.manageValue}
                        manageHandleChange={props.manageHandleChange}
                    />
                    */}
                    <FloatingContainerComp
                        property={props.property?.property_detail}
                        is_owner={props.property?.is_owner}
                    />
                    <ContactModuleComp
                        property={props.property?.property_detail}
                        simpleContact={true}
                        data={{
                            ...props.property?.negotiator_info,
                            helpTextLink: "Get in touch with me",
                            helpText: "Need help? ",
                        }}
                    />
                </>
            ),
        },
        {
            id: "tab2",
            name: "Activity",
            content: <RecentActivites property={props?.property} />,
        },
    ]
}
const TabsMobileComp = props => {
    return (
        <Tabs
            data={mobileTabData(props)}
            fullWidthTabs={true}
            altTabClassName={`pd-second`}
        />
    )
}

const MyAccountPropertySellerHeaderProperty = withSubtheme(props => {
    const { className, properties, property } = props
    return (
        <div className={className}>
            <SinglePropertyComp
                properties={properties}
                property={property?.property_detail}
            />
        </div>
    )
}, "myPropertyPageSingleHeaderProperty")

const MenuListTabsComp = props => {
    const origData = props?.data || mainTabData(props)
    // Create a new array with only the id and name properties, we don't need the rest for the menus
    const newData = collectMenuTabs(origData)

    return (
        <MenuListTabs
            {...props}
            title={props.title && props.title}
            data={newData}
        />
    )
}

const RightDrawerContent = ({ props }) => {
    return <MenuListTabsComp {...props} />
}


const collectProprtyInfo = (property, property_detail) => {

    return {
        title: "Property Information",

        values: [
          {
            name: "Status",
            value: camelCaseToNormalText(property_detail?.status)
          },{
            name: "Rent",
            value: <Price property={property_detail}/>
          },
        //   {
        //     name: "Letting Fee",
        //     value: property?.letting_fee || "--"
        //   },
        //   {
        //     name: "Management %",
        //     value: property?.management_fee || "--"
        //   }
        ]
      }
}

const collectTenancyInfo = (tenancy, property) => {
    console.log("tenancy => ", tenancy, property)

    if (isEmpty(tenancy))
        return null
    return {
        title: "Tenant Details",
        values: [
            {
                name: "Name",
                value: tenancy.applicant_name
            },{
                name: "Start Date",
                value: tenancy.start_date
            },
            {
                name: "End Date",
                value: tenancy.end_date
            },
            {
                name: "Deposit",
                value: property?.deposit || "--"
            }
        ]
    }
}

const MyAccountPropertySellerInit = React.memo(({ props }) => {
    const urlParams = useParams()
    const { theme, property, error, isError, isLoading } = props
    const properties =
        _properties ?? theme.getProp("subthemes.result.sampleData")
    const mobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true })

    const property_detail = property?.property_detail || {}
    const current_tenancy = property?.current_tenancy || {}
    const pageTitle = property_detail?.display_address

    if (isLoading) {
        return <MyPropertySkeleton tabsCount={7} />
    }

    if (!isLoading && isEmpty(property_detail)) {
        return (
            <NoActions />
        )
    }

    const actionMenuLists = [
        {
            name: "Documents",
            icon: 'customDocumentsIcon',
            lists: [
                {
                    text: "Statements",
                    link: "documents?type=Statement"
                },
                {
                    text: "Certificates",
                    link: "documents?type=Certificate"
                },
                {
                    text: "All Documents",
                    link: "documents"
                }
            ]
        },
        // {
        //     name: "Transaction",
        //     icon: 'customTransactionIcon',
        //     lists: [
        //         {
        //             text: "Invoice",
        //             link: "transactions?type=Invoice"
        //         },
        //         {
        //             text: "Transfer",
        //             link: "transactions?type=Transfer"
        //         },
        //         {
        //             text: "Income & Expenditure",
        //             link: "transactions/income-and-expenditure"
        //         }
        //     ]
        // },
        // {
        //     name: "Faults & Issues",
        //     icon: 'customFaultIssueIcon',
        //     lists: [
        //         {
        //             text: "Fixflo",
        //             link: theme.fixfloUrl
        //         },
        //         {
        //             text: "Maintenance History",
        //             link: "fault-and-issues"
        //         },
        //     ]
        // },
        {
            name: "Appointments",
            icon: 'viewingIcon',
            lists: [
                {
                    text: "Appointments",
                    link: "appointments"
                },
                {
                    text: "Offers",
                    link: "offers"
                },
                {
                    text: "Feedback",
                    link: "feedback"
                }
            ]
        }
    ]

    // Switching tabs    
    if (urlParams?.tab) {
        switch (urlParams?.tab) {
            case 'documents':
                return <DocumentContent {...props} type="lettings"role="seller" />
        
            case 'offers':
                return <OfferContent {...props} type="lettings"role="seller" />

            case 'appointments':
                return <AppointmentsContent {...props} type="lettings"role="seller" />
    
            case 'feedback':
                return <FeedbackContent {...props} type="lettings"role="seller" />
    
                    
            default:
                break;
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} className="Sanmugam-two">
                {/* <PageTitle theme={theme}>{pageTitle}</PageTitle> */}
                <SectionTitle
                    {...props}
                    title={pageTitle}
                    tabsDescription={''}
                    // tabsTitle={property_detail?.display_address}
                    actions={['contactus']}
                />
            </Grid>
            <Grid item xs={12}>
                <ThreeColumnGridWithImage property={property_detail} propertyInfo={collectProprtyInfo(property, property_detail)} tenancyInfo={collectTenancyInfo(current_tenancy, property)}/>
                <ThreeColumnGridWithMenu property={property_detail} actionMenuLists={actionMenuLists} urlParams={urlParams} />
                {/* <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <CustomCard />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <CustomCard btnProps={{variant:"outlined"}}/>
                    </Grid>
                </Grid> */}
            </Grid>
        </Grid>
    )
})


const WrapComp = React.memo(props => {
    const authUser = isAuthenticated()
    const { className, theme, propertyid } = props

    const {
        data: property,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetMyPropertyQuery({ id: propertyid }, { skip: !authUser })

    if (isLoading) {
        return <MyPropertySkeleton tabsCount={7} />
    }
    return (
        <div className={className}>
            <Container maxWidth="xl">
                <MyAccountPropertySellerInit
                    props={{
                        ...props,
                        theme: theme,
                        property: property,
                        error: error,
                        isError: isError,
                        isLoading: isLoading,
                    }}
                />
            </Container>
        </div>
    )
})

const MyAccountPropertySeller = withSubtheme(
    props => {
        return (
            <GlobalLayout>
                {/* <MyaccountStoreProvider> */}
                    <PropertyActionStoreProvider>
                        <WrapComp {...props}  persona="seller" />
                    </PropertyActionStoreProvider>
                {/* </MyaccountStoreProvider> */}
            </GlobalLayout>
        )
    },
    "myPropertyPageSeller",
    defaults
)

export default React.memo(MyAccountPropertySeller)
