import React, { memo } from 'react';
import NoActions from "../../../../progress/NoActions";
import { Divider, useMediaQuery, useTheme } from '@mui/material';
import Action from '../../../../actions/action/viewing/action';
import { FormStoreProvider } from "../../../../../../services";
import ComponentLayout from '../../../../ComponentLayout';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Grid, Card, CardContent, Typography, Link, Icon, IconButton, Box } from '@mui/material';
import { ComponentWithPagination } from "../../../../pagination"
import IntroText from '../tabIntro';
import StarberryIcons from '../../../../icons';
import { ListActionsBoxWrap } from '../../../../StyleComp';
import { displayData, getTime } from '../../../../helper/dateformat';
import { formatPrice } from '../../../../helper/action';
import { camelCaseToNormalText } from '../../../../property/utils';

const ListItem = ({data, image, ...rest}) => {
    console.log('rest =>>>', rest)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return(
        <ListActionsBoxWrap>
            {/* {data.map((item) => {
                return (
                    <div id={item.id} key={item.id}>
                        <FormStoreProvider>
                            <Action data={{...item, image:image}} {...rest} />
                        </FormStoreProvider>
                    </div>
                )
            })} */}
            {!isMobile ? (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Cost of Repair</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 1 ? 'background.default' : 'white',
                  }}
                >
                  <TableCell>
                    <Box className='table-data'>
                      <StarberryIcons iconName={'customFaultIssueIcon'} />
                      {row.title}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {displayData(row?.booked_date)}
                  </TableCell>
                  <TableCell>
                    {formatPrice(row?.total_gross_amount, true)}
                  </TableCell>
                  <TableCell>
                    {camelCaseToNormalText(row.status)}
                  </TableCell>
                  {/* <TableCell>
                    {row?.showActionButton && (
                        <Action data={{...row, image:image}} {...rest} roleType="owner" step={'default'} cardWithAction={true} />
                    )}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid spacing={2}>
          {data.map((row, index) => (
            <Card className="action-card-item" key={index}>
              <Grid container spacing={2} alignItems="baseline">
                <Grid item className="action-icon"><Typography>.</Typography>
                  <StarberryIcons iconName={'customFaultIssueIcon'} />
                </Grid>
                <Grid item xs>
                  <CardContent>
                    <Typography variant="h6">{row.title}</Typography>
                    <Typography className="info-text" variant="body2" color="textSecondary">
                        {displayData(row?.booked_date)}
                      <Divider />
                      {formatPrice(row?.total_gross_amount, true)}
                      <Divider />
                      {camelCaseToNormalText(row.status)}
                    </Typography>
                    {/* {row?.showActionButton && (
                        <Action data={{...row, image:image}} {...rest} roleType="owner" step={'default'} cardWithAction={true} />
                    )} */}
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          ))}
        </Grid>
      )}
        </ListActionsBoxWrap>
    )
}


const List = ({noRecord, data, title, ...rest}) => {
    const noResult = noRecord || !data.length;
    
    return(
        <>
            {(noResult) && (
                    <NoActions
                        title={`There are no ${title}`}
                        icon="documentsIcon"
                    />
                )}
                {!noResult && (
                <>
                    {/* <IntroText
                        {...rest}
                        className="m-pb-10"
                    /> */}
                    {/* <ComponentLayout
                        title={`${title} (${data.length})`}
                    > */}
                        <ComponentWithPagination
                            {...rest}
                            component={ListItem}
                            data={data}
                            marginTop={25}
                            useLoadMore={false}
                            // pageSize={1}
                        />
                    {/* </ComponentLayout> */}
                </>
            )}
        </>
    )
}

export default memo(List);