import React, { memo } from "react"
import NoActions from "../../../../progress/NoActions"
import Action from "../../../../actions/action/feedback"
import { FormStoreProvider } from "../../../../../../services"
import ComponentLayout from "../../../../ComponentLayout"
import { ComponentWithPagination } from "../../../../pagination"
import { Card, CardContent, Divider, Grid, Typography } from "@mui/material"
import { ListActionsBoxWrap } from "../../../../StyleComp"
import StarberryIcons from "../../../../icons"
import { displayData } from "../../../../helper/dateformat"

const ListItem = ({ data, image, showActionButton, ...rest }) => {

    return(
        <ListActionsBoxWrap>
            <Grid spacing={2}>
            {data.map((row, index) => (
                <Card className="action-card-item" key={index}>
                <Grid container spacing={2} alignItems="baseline">
                    <Grid item className="action-icon">
                    <StarberryIcons iconName={'feedbackIcon'} />
                    </Grid>
                    <Grid item xs>
                    <CardContent>
                        <Typography variant="h6">{row.title}</Typography>
                        <Typography className="info-text" variant="body2" color="textSecondary">
                            {displayData(row?.appointment_starttime)}
                            <Divider />
                            {'Received'}
                        </Typography>
                    </CardContent>
                    </Grid>
                </Grid>
                </Card>
            ))}
            </Grid>
        </ListActionsBoxWrap>
    )

    return (
        <>
            {data.map((item, key) => {
                return (
                    <div id={`feedback-${key}`} key={`feedback-${key}`}>
                        <FormStoreProvider>
                            <Action
                                data={{
                                    ...item,
                                    image: image,
                                    showActionButton: showActionButton,
                                }}
                                {...rest}
                            />
                        </FormStoreProvider>
                    </div>
                )
            })}
        </>
    )
}

const List = ({ noRecord, data, title, ...rest }) => {
    const noResult = noRecord || !data.length

    return (
        <>
            {noResult && (
                <NoActions
                    title={`There are no ${title}`}
                    icon="feedbackIcon"
                />
            )}
            {!noResult && (
                <>
                    {/* <ComponentLayout title={`${title} (${data.length})`}> */}
                        <ComponentWithPagination
                            {...rest}
                            component={ListItem}
                            data={data}
                            marginTop={25}
                            // useLoadMore={false}
                            // pageSize={1}
                        />
                    {/* </ComponentLayout> */}
                </>
            )}
        </>
    )
}

export default memo(List)
