/* eslint-disable import/first */

import React from "react"
import { Router } from "@reach/router"

// Ref: https://www.gatsbyjs.com/docs/how-to/routing/client-only-routes-and-user-authentication/

// Importing individual components like this (rather than
// `import {...} from '@material-ui/core';` ) makes for more efficient
// bundling, in theory :
// see https://material-ui.com/guides/minimizing-bundle-size/

import { useTheme } from "../../core/StarberryComponentsMui"
import {
    MYACCOUNT_MYPROPERTY,
    MYACCOUNT_MYPROPERTY_SELLING,
    MYACCOUNT_MYPROPERTY_BUYING,
    MYACCOUNT_MYPROPERTY_RENTING,
    MYACCOUNT_MYPROPERTY_LETTING,
    MYACCOUNT_MYPROPERTY_LETTING_ACTIONS,
    MYACCOUNT_LANDLAND,
    MYACCOUNT_MYPROPERTY_RENTING_ACTIONS,
    MYACCOUNT_MYPROPERTY_BUYING_ACTIONS,
    MYACCOUNT_MYPROPERTY_SELLING_ACTIONS,
    MYACCOUNT_MYPROPERTY_PERSONA,
    MYACCOUNT_MYPROPERTY_LETTING_SUB_ACTIONS,
} from "../../core/constants/urls";

import PrivateRoute from "../../core/components/MyAccount/hoc/PrivateRoute"
import MyProperty from "../../core/components/MyAccount/pages/my-property"
import MyPropertySeller from "../../core/components/MyAccount/pages/my-property/seller"
import MyPropertyTenant from "../../core/components/MyAccount/pages/my-property/tenant"
import MyPropertyBuyer from "../../core/components/MyAccount/pages/my-property/buyer"
import MyPropertyLandlord from "../../core/components/MyAccount/pages/my-property/landlord"
import _myAccount from "../../core/sample/myAccount.yaml"
import PageHeaderHelmet from "../../core/components/pageHeader";

const MyAccountPages = () => {

    const theme = useTheme()

    // In real use, the people in 'persons' will probably come from CMS,
    // while properties come from another data source.
    const myAccount =
        theme.getProp("subthemes.myAccount.sampleData") ?? _myAccount

    return (
        <>
            <PageHeaderHelmet theme={theme} />
            <Router>
                <PrivateRoute
                    path={MYACCOUNT_MYPROPERTY}
                    component={MyProperty}
                    myAccount={myAccount}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_MYPROPERTY_PERSONA}
                    component={MyProperty}
                    myAccount={myAccount}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_MYPROPERTY_SELLING}
                    component={MyPropertySeller}
                    myAccount={myAccount}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_MYPROPERTY_SELLING_ACTIONS}
                    component={MyPropertySeller}
                    myAccount={myAccount}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_MYPROPERTY_BUYING}
                    component={MyPropertyBuyer}
                    myAccount={myAccount}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_MYPROPERTY_BUYING_ACTIONS}
                    component={MyPropertyBuyer}
                    myAccount={myAccount}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_MYPROPERTY_RENTING}
                    component={MyPropertyTenant}
                    myAccount={myAccount}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_MYPROPERTY_RENTING_ACTIONS}
                    component={MyPropertyTenant}
                    myAccount={myAccount}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_MYPROPERTY_LETTING}
                    component={MyPropertyLandlord}
                    myAccount={myAccount}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_MYPROPERTY_LETTING_ACTIONS}
                    component={MyPropertyLandlord}
                    myAccount={myAccount}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_MYPROPERTY_LETTING_SUB_ACTIONS}
                    component={MyPropertyLandlord}
                    myAccount={myAccount}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_LANDLAND}
                    component={MyPropertyLandlord}
                    myAccount={myAccount}
                    theme={theme}
                />
            </Router>
        </>
    )
}

export default MyAccountPages
