export default {
    "props": {
        "tabsDescription": {
            "landing": "LandingText",
            "offers": "Offer:This is a line of text that would explain what is on display and also the property name.",
            "tenancy": "Tenanacy:This is a line of text that would explain what is on display and also the property name.",
            "fault-and-issues": "Faults & Issues:This is a line of text that would explain what is on display and also the property name.",
            "documents": "Documents:This is a line of text that would explain what is on display and also the property name.",
            "appointments": "Appointments:This is a line of text that would explain what is on display and also the property name.",
            "feedback": "Feedback:This is a line of text that would explain what is on display and also the property name.",
            "transactions": "Transactions:This is a line of text that would explain what is on display and also the property name.",
            "utilities": "Utilities:This is a line of text that would explain what is on display and also the property name.",
        },
        "buyer": {
            "tabOfferOfferBtn": true,
            "showAddIcon": true,
            "showBookaViewingBtn": true,
            "showMakeAnOfferBtn": true
        },
        "tenant": {
            "tabOfferOfferBtn": true,
            "showAddIcon": true,
            "showBookaViewingBtn": true,
            "showMakeAnOfferBtn": true
        },
        "seller": {

        },
        "landlord": {
            "showPendingAction": true,
            // "tabsDescription": {
            //     "offers": "" // You can overwrite persona wise
            // }
            "canDownloadStatement": false
        }
    },
    "sx": (theme) => ({
        "& .mobile-tab-title": {
            "fontWeight": theme.typography.fontWeightBold,
            "fontSize": theme.bodySmall,
            "marginBottom": theme.spacing(0.6),
        },
        "& .mobile-tab-intro": {
            "fontSize": theme.bodySmall,
        },
        "& .mobile-tab-menu": {
            "marginBottom": theme.spacing(2),
        },
        "& .mobile-tab-menu-list": {
            "marginTop": theme.spacing(1.5),
            "padding": theme.spacing(1.5,2),
            "borderRadius": "4px",
            "boxShadow": "0px 0px 1px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 2px 4px 0px rgba(0, 0, 0, 0.06)",
            // [theme.breakpoints.up('sm')]: {
            //     "padding": theme.spacing(1.5,2),
            // },
            "& svg": {
                "& path": {
                    "stroke": theme.palette.grey.grey1
                }
            }
        },
        "& .m-pb-20": {
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                marginBottom: theme.spacing(2)
            },
        },
        "& .m-pb-10": {
            marginBottom: theme.spacing(0.5),
            [theme.breakpoints.up('md')]: {
                marginBottom: theme.spacing(1)
            },
        },
        // feedback
        "& .feedback-summary-inner": {
            [theme.breakpoints.down('lg')]: {
                "boxShadow": "none",
                "paddingLeft": "0",
                "paddingRight": "0"
            },
            "& .globalComponentLayout-title": {
                "fontWeight": theme.typography?.layoutTitle?.fontWeightBold || theme.typography.fontWeightBold,
                "fontFamily": theme.typography?.layoutTitle?.fontFamily || 'initial',
                "color": `${theme.palette.text.primary} !important`,
                "fontSize": theme.h6,
                [theme.breakpoints.up('sm')]: {
                    "fontSize": theme.h5,
                    "lineHeight": theme.h5LineH,
                    "color": theme.palette.text.primary,
                },
            },
        }
    })
}
