import React from "react"
import { useParams } from '@reach/router';
import { get } from "lodash"
// import NoActions from "../../../../progress/NoActions";
// import Actions from '../../../../actions';
import CreateTenancy from "../../../../PropertyResult/CreateTenancy"
import ActionsSkeletonWithMore from "../../../../actions/actionsSkeletonWithMore"
import ListItem from "./list"
import {
    useGetPropertyTenanciesQuery,
    propertyApi,
} from "./../../../../../../redux/services/property"
import store from "./../../../../../../redux/store"
import { isAuthenticated } from "../../../../../../services/store/utils"
import IntroText from "../tabIntro";
import SectionTitle from "../sectionTitle";
import { DocumentBox, FilterBox } from "../../../../StyleComp";
import { Box, Grid, Typography } from "@mui/material";
import myaccountData from '../../../../../../../../static/data/myaccount.json';

const UtilityContent = props => {
    const utilities = props.property?.current_tenancy?.utilities
    const urlParams = useParams()
    const tabName = urlParams?.tab;
    // const authUser = isAuthenticated()
    // const {
    //     data: propertyTenances,
    //     error,
    //     isError,
    //     isLoading,
    //     isFetching,
    // } = useGetPropertyTenanciesQuery(
    //     { property_id: property.crm_id },
    //     { skip: !authUser }
    // )

    const property_detail = props?.property?.property_detail || {}
    
    const offerData = utilities || []
    const noRecord = !offerData.length
    const actionTitle = props?.offer?.actionTitle || "Utility Readings"
    const propertyImage = props.property.property_detail?.thumbnail

    const reloadDataFun = React.useCallback(() => {
        store.dispatch(
            propertyApi.util.invalidateTags([
                // "PendingActionCount",
                "PropertyTenancies",
            ])
        )
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // if (isLoading) {
    //     return <ActionsSkeletonWithMore title />
    // }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
            <SectionTitle
                {...props}
                title={`Utility Readings`}
                tabsDescription={'utility_reading'}
                tabsTitle={property_detail?.display_address}

                // for offer Btn
                // showAddIcon={showAddIcon}
                // property={props.property.property_detail}
                // reloadDataFun={reloadDataFun}
                // actions={['contactus']}
            />

            <DocumentBox>
                {/* <Box className="content"> */}
                    <Grid container spacing={1}>        
                        <Grid item xs={12}>
                            <ListItem
                                noRecord={noRecord}
                                data={offerData}
                                image={propertyImage}
                                title={actionTitle}
                                // title="Tenancies"
                                reloadDataFun={reloadDataFun}
                            />
                        </Grid>
                    </Grid>
                {/* </Box> */}
            </DocumentBox>
        </Grid>
        </Grid>
    )
}

export default UtilityContent
