import React from "react"
import { useParams } from '@reach/router';
import { get } from "lodash"
// import NoActions from "../../../../progress/NoActions";
// import Actions from '../../../../actions';
import CreateTenancy from "../../../../PropertyResult/CreateTenancy"
import ActionsSkeletonWithMore from "../../../../actions/actionsSkeletonWithMore"
import ListItem from "./list"
import {
    useGetPropertyTenanciesQuery,
    propertyApi,
} from "./../../../../../../redux/services/property"
import store from "./../../../../../../redux/store"
import { isAuthenticated } from "../../../../../../services/store/utils"
import IntroText from "../tabIntro";
import SectionTitle from "../sectionTitle";
import { DocumentBox, FilterBox } from "../../../../StyleComp";
import { Box, Grid, Typography } from "@mui/material";
import myaccountData from '../../../../../../../../static/data/myaccount.json';

const TenancyContent = props => {
    const property = props.property.property_detail
    const urlParams = useParams()
    const tabName = urlParams?.tab;
    const authUser = isAuthenticated()
    const {
        data: propertyTenances,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetPropertyTenanciesQuery(
        { property_id: property.crm_id },
        { skip: !authUser }
    )

    const property_detail = props?.property?.property_detail || {}
    
    const offerData = propertyTenances || []
    const noRecord = !isLoading && !offerData.length
    const actionTitle = props?.offer?.actionTitle || "Tenancies"
    const propertyImage = props.property.property_detail?.thumbnail
    const showOfferBtn = get(
        props,
        `${props.role || ""}.tabOfferOfferBtn`,
        false
    )
    const showAddIcon = false // get(props, `${props.role || ""}.showAddIcon`, false)

    const reloadDataFun = React.useCallback(() => {
        store.dispatch(
            propertyApi.util.invalidateTags([
                // "PendingActionCount",
                "PropertyTenancies",
            ])
        )
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading) {
        return <ActionsSkeletonWithMore title />
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
            <SectionTitle
                {...props}
                title={`Tenancies`}
                tabsDescription={'tenancy'}
                tabsTitle={property_detail?.display_address}

                // for offer Btn
                showAddIcon={showAddIcon}
                property={props.property.property_detail}
                reloadDataFun={reloadDataFun}
                actions={[(showOfferBtn ? 'makeanoffer' : ''), 'contactus']}
            />

            <DocumentBox>
                <Box className="content">
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box className="filter-box-wrap">
                                {!!offerData.length && <Typography>Showing {offerData.length} offers</Typography>}
                                <FilterBox>
                                {/* <p>asas</p> */}
                                </FilterBox>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <ListItem
                                noRecord={noRecord}
                                data={offerData}
                                image={propertyImage}
                                title={actionTitle}
                                // title="Tenancies"
                                reloadDataFun={reloadDataFun}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DocumentBox>
        </Grid>
        </Grid>
    )
}

export default TenancyContent
