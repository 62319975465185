import React from 'react';
import { Box, Grid, Typography, List, ListItem, ListItemText, ListItemIcon, Divider, Link as MuiLink } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { margin, padding, styled } from '@mui/system';
import StarberryIcons from '../../../../icons';
import { doRedirect, handleTabNavigation } from '../../../../utils';

const MenuItemComponent = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.primary.main,
  },
  '&:hover .MuiListItemIcon-root': {
    color: theme.palette.primary.main,
  }
}));

const BoxComponent = styled(Box)(({ theme }) => ({
  marginBottom: "10px",
  p: 0,
  borderRadius: 1,
  height: '100%',
  bgcolor: 'transparent',
  boxShadow: 'none',
  background: 'white',
  borderRadius: '8px',
  boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 2px 4px 0px rgba(0, 0, 0, 0.06)',
  [theme.breakpoints.down('md')]: {
    borderRadius: '0',
    boxShadow: 'none',
    padding: 0
  },
  "& svg":{
    "& path": {
        stroke: theme?.palette?.icon?.default,
    },
  },
  [theme.breakpoints.up('lg')]: {
    marginBottom: "0",
  },
  [theme.breakpoints.down('lg')]: {
    background: 'white',
    borderRadius: '8px',
    boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 2px 4px 0px rgba(0, 0, 0, 0.06)',
    padding: "1.25rem 1.25rem 0.5rem 1.25rem",
    "& .card-title": {
      paddingBottom: "1.25rem",
      marginBottom: "0.5rem !important",
      borderBottom: `1px solid ${theme?.palette?.background?.border}`,
    },
  },
  "& .card-title": {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    "& h2": {
      marginLeft: theme.spacing(1),
      marginBottom: 0
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(-7),
      marginLeft: theme.spacing(-2),
    }

  },
  "& .card-info": {
    padding: "0.5rem 0.25rem", 
    [theme.breakpoints.up('md')]: {
      padding: "0.5rem 1.25rem", 
    }
    // background: 'white',
    // borderRadius: '8px',
    // boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 2px 4px 0px rgba(0, 0, 0, 0.06)',
    // [theme.breakpoints.down('md')]: {
    //   borderRadius: '0',
    //   boxShadow: 'none',
    //   padding: 0
    // },
  }
}));

const ComponentWrap = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  p: 0,
  margin: theme.spacing(4, 0, 0, 0),
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(2, 0, 4, 0),
  },
  [theme.breakpoints.up('lg')]: {
    margin: theme.spacing(12, 0, 4, 0),
  }
}));


const GridColumn = ({ title, icon, menuItems, urlParams }) => {
  
  const handleClick = (e, tabLink) => {
    if (!(tabLink.match(/https|http/))) {
      e.preventDefault()
      doRedirect(tabLink)
    }
  };

  return(
  <BoxComponent>
    
    <List className="card-info">
      <Box className="card-title">
        {icon}
        <Typography variant="h2" gutterBottom>
          {title}
        </Typography>
      </Box>
      {menuItems.map((item, index) => {

        let link = item.link
        const externalLink = (link.match(/https|http/))
        let linkParams = {}
        if (!externalLink) {
          link = handleTabNavigation( item.link, urlParams ,true);
        } else {
          linkParams = {
            target: "_blank",
          }
        }
        console.log('linkParams', linkParams, link)
        return(
          <React.Fragment key={index}>
            <MuiLink
                {...linkParams}
                //  target="_blank"
                href={link} 
                onClick={(e) => handleClick(e, link)}
                underline="none"
                sx={{ color: 'secondary.main' }}
                >
              <MenuItemComponent sx={{padding: "5px 0"}}>
                <ListItemText primary={item.text} />
                <ListItemIcon sx={{minWidth: "auto"}}>
                  <StarberryIcons iconName="CustomChevronRightIcon" />
                </ListItemIcon>
              </MenuItemComponent>
            </MuiLink>
            {index < menuItems.length - 1 && <Divider sx={{ borderColor:'background.border',bgcolor: 'background.border' }}/>}
          </React.Fragment>
        )
      })}
    </List>
  </BoxComponent>
)};

const ThreeColumnGrid = ({actionMenuLists, urlParams}) => {

  return (
    <ComponentWrap sx={{ flexGrow: 1, p: 0 }}>
      <Grid container spacing={2}>
        {actionMenuLists.map((item) => {
          return (
            <Grid item xs={12} md={6} lg={3}>
              <GridColumn
                title={item.name}
                icon={<StarberryIcons iconName={item.icon} />}
                menuItems={item.lists}
                urlParams={urlParams}
              />
            </Grid>
          )
        })}
        {/* <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <GridColumn
              title="Documents"
              icon={<StarberryIcons iconName="customDocumentsIcon" />}
              menuItems={menuItems1}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <GridColumn
              title="Title 2"
              icon={<StarIcon />}
              menuItems={menuItems2}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <GridColumn
              title="Title 3"
              icon={<StarIcon />}
              menuItems={menuItems3}
            />
          </Grid>*/}
      </Grid>
    </ComponentWrap>
  );
};

export default ThreeColumnGrid;
