import React from "react"
import { get } from "lodash"
import { useParams } from '@reach/router';
import Grid from "@mui/material/Grid"
import NoActions from "../../../../progress/NoActions"
import Tabs from "../../../../tabs"
import FloatingContainer from "../../../../floatingContainer"
import ContactBtn from "../../../../property/components/contactBtn"
import ReportFaultBtn from "../../../../property/components/reportFaultBtn"
import SingleProperty from "../../../../property/singleProperty"
import _properties from "../../../../../../sample/properties.yaml"
import Stack from "@mui/material/Stack"
import TabContainerSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/TabContainerSkeleton"
import TabSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/TabSkeleton"
import AutoHeightSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/AutoHeightSkeleton"
import BodyLargeSkeleton from "../../../../../../StarberryComponentsMui/components/Skeleton/BodyLargeSkeleton"
import ListItem from "./list"
import {
    useGetPropertyWorksorderQuery,
    propertyApi,
} from "./../../../../../../redux/services/property"
import store from "./../../../../../../redux/store"
import { isAuthenticated } from "../../../../../../services/store/utils"
import IntroText from "../tabIntro";
import { DocumentBox, FilterBox } from "../../../../StyleComp";
import { Box, Typography } from "@mui/material";
import SectionTitle from "../sectionTitle";
import { canShowNewDocumentBtn } from "../../../../property/components/newDocument/utils";
import myaccountData from '../../../../../../../../static/data/myaccount.json';
import CircularProgressComponent from "../../../../progress/CircularProgress";

const SinglePropertyComp = props => {
    let offerText = ""
    if (props.property?.offers && props.property.offers.length) {
        offerText = `${props.property.offers.length} offers received`
    }

    let property = _properties[0] // Use default data if the property data is not there

    if (props.property) {
        property = {
            ...props.property,
            offerText: offerText,
        }
    }

    return (
        <>
            <SingleProperty properties={[property]} />
        </>
    )
}

const ReportFaultBtnComp = props => {
    const reloadDataFun = React.useCallback(() => {
        store.dispatch(propertyApi.util.invalidateTags(["PropertyWorksorder"]))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <ReportFaultBtn {...props} reloadDataFun={reloadDataFun} />
}

const FloatingContainerComp = props => {
    return (
        <FloatingContainer>
            <ContactBtn />
            {props?.current_tenancy_id && !props.is_owner && (
                <ReportFaultBtnComp btnLabel="Report" {...props} />
            )}
        </FloatingContainer>
    )
}

const RecentActivites = ({ data, ...rest }) => {
    const noRecord = !data.length

    return <ListItem {...rest} noRecord={noRecord} data={data} />
}

const ActionsComp = ({ data, ...rest }) => {
    const noRecord = !data.length
    const propertyImage = rest?.property?.thumbnail

    return (
        <ListItem
            {...rest}
            data={data}
            noRecord={noRecord}
            image={propertyImage}
        />
    )
    // return (
    //     <>
    //         {noRecord && (
    //             <NoActions
    //                 title="There are no open items"
    //                 icon="faultsIcon"
    //             />
    //         )}
    //         {!noRecord && (
    //             <Actions {...rest} data={data} image={propertyImage} />
    //         )}
    //     </>
    // )
}

const faultTabData = props => {
    const open_items = props?.data?.open_items || []
    const closed_items = props?.data?.closed_items || []
    return [
        {
            id: "tab1",
            name: "Open",
            content: <ActionsComp {...props} data={open_items} />,
        },
        {
            id: "tab2",
            name: "Closed",
            content: <RecentActivites data={closed_items} />,
        },
    ]
}
const TabsFaultComp = props => {
    return <Tabs data={faultTabData(props)} fullWidthTabs={true} />
}
const FaultIssuesContent = props => {
    const { isMobile, role } = props

    const authUser = isAuthenticated()
    const property = props.property.property_detail
    const urlParams = useParams()
    const tabName = urlParams?.tab;

    const {
        data: worksOrders,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetPropertyWorksorderQuery(
        { property_id: property.crm_id },
        { skip: !authUser }
    )

    let offerData = []
    if (worksOrders?.open_items) {
        offerData = [...offerData, ...worksOrders?.open_items]
    }
    if (worksOrders?.closed_items) {
        offerData = [...offerData, ...worksOrders?.closed_items]
    }
    const actionTitle = props?.offer?.actionTitle || "Faults & Issues"
    const tabsDescription = get(props, `${props?.persona}.tabsDescription.fault-and-issues`, get(props, 'tabsDescription.fault-and-issues', false))
    const noRecord = !isLoading && !offerData.length

    const reloadDataFun = React.useCallback(() => {
        store.dispatch(propertyApi.util.invalidateTags(["PropertyWorksorder"]))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading && !offerData.length) {
        return <CircularProgressComponent />
        return (
            <Grid container>
                <Grid item md={8}>
                    <TabContainerSkeleton noBorder>
                        <TabSkeleton />
                        <TabSkeleton />
                    </TabContainerSkeleton>
                </Grid>
                <Grid item md={4}>
                    <Stack direction="column" spacing={4}>
                        <AutoHeightSkeleton
                            width="100%"
                            aspectRatio={6 / 19}
                            variant="rectangular"
                        />
                        <BodyLargeSkeleton width="50%" />
                    </Stack>
                </Grid>
            </Grid>
        )
    }


    const params = {
        property: props.property?.property_detail,
        is_owner: props.property?.is_owner,
        current_tenancy_id: props.property?.current_tenancy_id,
        owner_type: props.property?.owner_type,
        tenant_details: props.property?.current_tenancy?.tenant_details,
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
            <SectionTitle
                {...props}
                title={`Faults & Issues`}
                tabsDescription={'faults_and_issues'}
                tabsTitle={property?.display_address}

                property={props.property.property_detail}
                actions={[(canShowNewDocumentBtn(params) && role === 'tenant') ? 'report-fault' : '', 'contactus']}
            />

            <DocumentBox>
                <Box className="content">
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box className="filter-box-wrap">
                                <Typography>Showing {offerData.length || 0} Faults & Issues</Typography>
                                <FilterBox>
                                {/* <p>asas</p> */}
                                </FilterBox>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <ListItem
                                noRecord={noRecord}
                                data={offerData}
                                // image={propertyImage}
                                title={actionTitle}
                                // title="Tenancies"
                                reloadDataFun={reloadDataFun}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DocumentBox>
        </Grid>
        </Grid>
    )

}

export default FaultIssuesContent
