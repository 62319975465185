import React, { memo } from 'react';
import NoActions from "../../../../progress/NoActions";
// import Action from '../../../../actions/action/tenancy';
import Action from '../../../../actions/action/offer/action';
import { FormStoreProvider } from "../../../../../../services";
import ComponentLayout from '../../../../ComponentLayout';
import { ComponentWithPagination } from "../../../../pagination/"
import { Divider, useMediaQuery, useTheme } from '@mui/material';
import { ListActionsBoxWrap } from '../../../../StyleComp';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Grid, Card, CardContent, Typography, Link, Icon, IconButton, Box } from '@mui/material';
import { displayData } from '../../../../helper/dateformat';
import StarberryIcons from '../../../../icons';
import DownloadBtn from '../document/downloadBtn';
import { formatPrice } from '../../../../helper/action';
import { getRentFrequencyCode } from '../../../../helper';
import { getTitleTenancy, getStatusTenancy, getDateTenancy, getStatusOffer, getDateOffer } from '../utils';

const getIconName = (key) => {
  switch (key) {
    case 'Current':
      return 'currentIcon'

    case 'Completed':
      return 'completedIcon'

    case 'Pending':
      return 'customDocumentsIcon'
    
    case 'Rejected':
      return 'rejectedIcon'

    default:
      return 'customDocumentsIcon';
  }
}

const ListItem = ({data, image, ...rest}) => {
    console.log('rest =>>>', rest)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return(
        <ListActionsBoxWrap>
            {/* {data.map((item) => {
                return (
                    <div id={item.id} key={item.id}>
                        <FormStoreProvider>
                            <Action data={{...item, image:image}} {...rest} />
                        </FormStoreProvider>
                    </div>
                )
            })} */}
            {!isMobile ? (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
                {/* <TableCell></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 1 ? 'background.default' : 'white',
                  }}
                >
                  <TableCell>
                    <Box className='table-data'>
                      <StarberryIcons iconName={getIconName(getStatusTenancy(row))} />
                      {row.display_title}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {row.display_date}
                  </TableCell>
                  <TableCell>
                    {formatPrice(row.value)}
                  </TableCell>
                  <TableCell>
                    {row?.status || '---'}
                  </TableCell>
                  {/* <TableCell>
                    {row?.showActionButton && (
                        <Action data={{...row, image:image}} {...rest} roleType="owner" step={'default'} cardWithAction={true} />
                    )}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid spacing={2}>
          {data.map((row, index) => (
            <Card className="action-card-item" key={index}>
              <Grid container spacing={2} alignItems="baseline">
                <Grid item className="action-icon"><Typography>.</Typography>
                  <StarberryIcons iconName={getIconName(getStatusTenancy(row))} />
                </Grid>
                <Grid item xs>
                  <CardContent>
                    <Typography variant="h6">{row.display_title}</Typography>
                    <Typography className="info-text" variant="body2" color="textSecondary">
                      {row.display_date}
                      <Divider />
                      {formatPrice(row.rent)} {getRentFrequencyCode(row.rent_frequency)}
                      <Divider />
                      {row?.status || '---'}
                    </Typography>
                    {row?.showActionButton && (
                        <Action data={{...row, image:image}} {...rest} roleType="owner" step={'default'} cardWithAction={true} />
                    )}
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          ))}
        </Grid>
      )}
        </ListActionsBoxWrap>
    )
}

const List = ({noRecord, data, title, ...rest}) => {
    const noResult = noRecord || !data.length;
    
    return(
        <>
            {(noResult) && (
                    <NoActions
                        title={`There are no ${title}`}
                        icon="tenancyIcon"
                    />
                )}
                {!noResult && (
                <>
                    {/* <ComponentLayout
                        // title={`${title} (${data.length})`}
                    > */}
                        <ComponentWithPagination
                            {...rest}
                            component={ListItem}
                            data={data}
                            marginTop={25}
                            useLoadMore={false}
                            // pageSize={1}
                        />
                    {/* </ComponentLayout> */}
                </>
            )}
        </>
    )
}

export default memo(List);