import React, { memo } from 'react';
import NoActions from "../../../../progress/NoActions";
import { Divider, useMediaQuery, useTheme } from '@mui/material';
import Action from '../../../../actions/action/viewing/action';
import { FormStoreProvider } from "../../../../../../services";
import ComponentLayout from '../../../../ComponentLayout';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Grid, Card, CardContent, Typography, Link, Icon, IconButton, Box } from '@mui/material';
import { ComponentWithPagination } from "../../../../pagination"
import IntroText from '../tabIntro';
import StarberryIcons from '../../../../icons';
import { ListActionsBoxWrap } from '../../../../StyleComp';
import { displayData, getTime } from '../../../../helper/dateformat';
import { getDateAppointment, getStatusAppointment, getTitleAppointment } from '../utils';

// const getTitleAppointment = (row) => {
//     let str = ['Property'];
//     if (row?.action)
//       str.push(row.action)
//     if (row?.applicant_name)
//       str.push(`with ${row.applicant_name}`)
  
//     return str.join(' ')
// }

// const getStatusAppointment = (item) => {
//     if (item.is_owner) {
//         if (item.completed)
//             return 'Completed'
//         else if (item.cancelled)
//             return 'Cancelled'
//         else if (item.feedback_pending === false)
//             return 'Feedback Pending'
//         else if (item.property_confirmed)
//             return 'Confirmed'
//         else
//             return 'Confirmed'    
//     } else {
//         if (item.completed && item.feedback_pending)
//             return 'Completed'
//         else if (item.cancelled)
//             return 'Cancelled'
//         else if (item.feedback_pending === false)
//             return 'Feedback Pending'
//         else if (item.property_confirmed)
//             return 'Confirmed'
//         else
//             return 'Confirmed'    
//     }
// }


const ListItem = ({data, image, ...rest}) => {
    console.log('rest =>>>', rest)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return(
        <ListActionsBoxWrap>
            {/* {data.map((item) => {
                return (
                    <div id={item.id} key={item.id}>
                        <FormStoreProvider>
                            <Action data={{...item, image:image}} {...rest} />
                        </FormStoreProvider>
                    </div>
                )
            })} */}
            {!isMobile ? (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Status</TableCell>
                {/* <TableCell></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 1 ? 'background.default' : 'white',
                  }}
                >
                  <TableCell>
                    <Box className='table-data'>
                      <StarberryIcons iconName={'viewingIcon'} />
                      {row.display_title}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {row.display_date}
                  </TableCell>
                  <TableCell>
                    {row.display_time}
                  </TableCell>
                  <TableCell>
                    {row?.status || '---'}
                  </TableCell>
                  {/* <TableCell>
                    {row?.showActionButton && (
                        <Action data={{...row}} {...rest} roleType="owner" step={'default'} cardWithAction={true} />
                    )}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid spacing={2}>
          {data.map((row, index) => (
            <Card className="action-card-item" key={index}>
              <Grid container spacing={2} alignItems="baseline">
                <Grid item className="action-icon"><Typography>.</Typography>
                  <StarberryIcons iconName={'viewingIcon'} />
                </Grid>
                <Grid item xs>
                  <CardContent>
                    <Typography variant="h6">{row.display_title}</Typography>
                    <Typography className="info-text" variant="body2" color="textSecondary">
                      {row.display_date}
                      <Divider />
                      {row.display_time}
                      <Divider />
                      {row?.status || '---'}
                    </Typography>
                    {/* {row?.showActionButton && (
                        <Action data={{...row, image:image}} {...rest} roleType="owner" step={'default'} cardWithAction={true} />
                    )} */}
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          ))}
        </Grid>
      )}
        </ListActionsBoxWrap>
    )
}


const List = ({noRecord, data, title, ...rest}) => {
    const noResult = noRecord || !data.length;
    
    return(
        <>
            {(noResult) && (
                    <NoActions
                        title={`There are no ${title}`}
                        icon="documentsIcon"
                    />
                )}
                {!noResult && (
                <>
                    {/* <IntroText
                        {...rest}
                        className="m-pb-10"
                    /> */}
                    {/* <ComponentLayout
                        title={`${title} (${data.length})`}
                    > */}
                        <ComponentWithPagination
                            {...rest}
                            component={ListItem}
                            data={data}
                            marginTop={25}
                            // useLoadMore={false}
                            // pageSize={1}
                        />
                    {/* </ComponentLayout> */}
                </>
            )}
        </>
    )
}

export default memo(List);